<template>
  <div class="">
    <div class="tw-font-bold tw-text-lg tw-ml-2 tw-my-4">Allotment Preview</div>
    <div class="tw-flex flex-wrap tw-justify-center">
      <div class="tw-w-2/4">
        <Calendar :form="form" @getRoundTime="getRoundTime" :allowDay="true" />
      </div>
      <div class="tw-w-full">
        <p class="tw-font-bold tw-text-base">Round</p>
        <div class="tw-flex tw-w-full tw-justify-center tw-mb-4">
          <div v-if="form.date === null" class="">Please select date</div>
          <div v-else-if="!optionRoundTime.length" class="">No RoundTime</div>
          <div class="" v-else>
            <div class="time" v-if="!isLoadingRoundTime">
              <div
                class="box-time"
                :class="handleClassRoundTime(item)"
                v-for="item in optionRoundTime"
                :key="item.id"
              >
                <div class="" @click="handleClickRoundTime(item)">
                  {{ item.timeStart }}
                </div>
              </div>
            </div>
            <div class="text-center text-black my-2" v-else>
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-w-full">
        <div class="tw-font-bold tw-text-lg tw-my-6">
          <span v-if="form.date">{{ form.date }}</span>
          <span v-if="form.roundTimeEventId"> / {{ roundTimeDisplay }}</span>
        </div>
        <TableEventZone :data="dataZoneTable" :isBusy="isLoadingTable" />
        <!-- <div class="tw-font-bold tw-text-lg tw-my-6"></div>
        <TableEventProduct :data="dataProductTable" :isBusy="isLoadingTable" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "../../../../transaction/createTransaction/component/calendar.vue";
import TableEventZone from "../tap3/TableEventZone.vue";
import TableEventProduct from "../tap3/TableEventProduct.vue";
export default {
  name: "AllotmentPreview",
  components: { Calendar, TableEventZone, TableEventProduct },
  data() {
    return {
      form: {
        eventId: this.$route.params.id,
        eventTypeId: 1,
        date: null,
      },
      optionRoundTime: [],
      isLoadingRoundTime: false,
      dataZoneTable: [],
      dataProductTable: [],
      roundTimeDisplay: "",

      isLoadingTable: false,
    };
  },
  computed: {},
  watch: {
    "form.date"(val) {
      this.getSold();
    },
  },
  methods: {
    async getRoundTime(date) {
      this.isLoadingRoundTime = true;
      this.roundTimeDisplay = "";
      this.form.roundTimeEventId = null;
      this.priceCalculated = {};
      this.optionProduct = {};
      this.form.date = date;
      const respone = await this.$services.eventgroup.getRoundTime(
        this.form.date,
        this.form.eventTypeId,
        this.form.eventId
      );
      if (respone.result === 1) {
        this.optionRoundTime = respone.detail;
      }
      this.isLoadingRoundTime = false;
    },
    async getSold() {
      this.dataZoneTable = [];
      this.dataProductTable = [];
      this.isLoadingTable = true;
      const query = {
        eventId: this.$route.params.id,
        roundTimeEventId: this.form.roundTimeEventId || null,
        bookingDate: this.form.date || null,
      };

      const respone = await this.$services.sold.getSold(query);

      this.dataZoneTable = respone.detail.groupZone;
      this.dataProductTable = respone.detail.groupProduct;
      this.isLoadingTable = false;
    },
    handleClassRoundTime(obj) {
      let newClass = [];
      let { roundTimeEventId } = obj;

      if (this.form.roundTimeEventId == roundTimeEventId) {
        newClass.push("select");
      }

      newClass.push("can");

      newClass = newClass.join(" ");

      return newClass;
    },
    handleClickRoundTime(val) {
      this.isLoadingRoundTime = true;
      const { roundTimeEventId } = val;

      this.form.roundTimeEventId = roundTimeEventId;

      const find = this.optionRoundTime.find(
        (item) => item.roundTimeEventId === roundTimeEventId
      );

      if (find) {
        this.roundTimeDisplay = `${find.timeStart} - ${find.timeEnd}`;
      }

      this.isLoadingRoundTime = false;
      this.getSold();
    },
  },
};
</script>

<style lang="scss" scoped>
.time {
  display: flex;
  flex-wrap: wrap;

  .box-time {
    border-radius: 20px;
    padding: 0 10px;
    margin: 0px 10px 10px 0px;

    &.select {
      cursor: pointer;

      color: white !important;

      border: 1px solid var(--secondary-color);
      background: var(--secondary-color);
    }

    &.can {
      cursor: pointer;

      p {
        color: var(--secondary-color);
      }

      border: 1px solid var(--secondary-color);
      color: var(--secondary-color);
    }

    &.not {
      cursor: default;

      p {
        color: var(--secondary-color);
      }

      border: 1px solid var(--secondary-color);
      color: var(--secondary-color);
      opacity: 0.3;
    }
  }
}
</style>
