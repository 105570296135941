<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="data"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
          <template #cell(quantity)="{ item }">
            <p class="m-0">{{ item.quantity }} / {{ item.zoneQuantity }}</p>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "TableEventZone",
  props: {
    data: {
      type: Array,
      default: [],
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fields() {
      return [
        {
          key: "startTime",
          label: "StartTime",
          class: "w-50px text-nowrap",
        },
        {
          key: "endTime",
          label: "EndTime",
          class: "w-50px text-nowrap",
        },
        {
          key: "zoneName",
          label: "Client",
          class: "w-50px text-nowrap",
        },
        {
          key: "quantity",
          label: "Quantity",
          class: "w-50px text-nowrap",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
