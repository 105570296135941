<template>
  <div class="tap3">
    <h1 class="title">
      Schedule
      <b-icon
        class="icon"
        icon="pencil-fill"
        @click="openSidebarEditSchedule"
      ></b-icon>
    </h1>
    <div class="sec1">
      <b-row v-if="dataSchedule">
        <b-col cols="6" class="mb-2">
          <div class="font-weight-bold">From Date / time</div>
          <p class="m-0">
            {{
              dataSchedule.startReserveDate
                ? $moment(dataSchedule.startReserveDate)
                    .locale("th")
                    .format("Do/MM/YYYY")
                : "-"
            }}
          </p>
        </b-col>
        <b-col cols="6" class="mb-2">
          <div class="font-weight-bold">To Date / time</div>
          <p class="m-0">
            {{
              dataSchedule.endReserveDate
                ? $moment(dataSchedule.endReserveDate)
                    .locale("th")
                    .format("Do/MM/YYYY")
                : "-"
            }}
          </p>
        </b-col>
        <b-col cols="6" class="mb-2">
          <div class="font-weight-bold">Dutation (Minute)</div>
          <p class="m-0">
            {{ dataSchedule.dutation }}
          </p>
        </b-col>
        <b-col cols="6" class="mb-2">
          <div class="font-weight-bold">Buffee Time After (Minute)</div>
          <p class="m-0">
            {{ dataSchedule.bufferTimeAfter }}
          </p>
        </b-col>
      </b-row>
      <b-row v-if="quantity"
        ><b-col cols="6" class="mb-2">
          <div class="font-weight-bold">Quantity</div>
          <p class="m-0">
            {{ dataSchedule.quantity }}
          </p></b-col
        ></b-row
      >
      <SideBarFilter
        v-if="dataScheduleClone"
        ref="sidebarSchedule"
        title="Schedule"
        textSubmit="Save"
        :filter="dataScheduleClone"
        :hideStatusFilter="false"
        :hideSearchBar="true"
        @searchAll="editSchedule"
        @clearForm="clearFormSchedule"
        handleSideBar
        hideFilter
        :isLoading="isLoading"
        @clearLoading="isLoading = false"
      >
        <template v-slot:filter-option>
          <div class="my-3">
            <div class="font-weight-bold mb-2">From Date / time</div>
            <div :class="['input-container']">
              <datetime
                v-model="dataScheduleClone.startReserveDate"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="startReserveDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.startReserveDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="my-3">
            <div class="font-weight-bold mb-2">To Date / time</div>
            <div :class="['input-container']">
              <datetime
                v-model="dataScheduleClone.endReserveDate"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="endReserveDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.endReserveDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>

          <div class="my-3">
            <InputText
              v-model="dataScheduleClone.dutation"
              class="input"
              textFloat="Dutation (Minute)"
              placeholder="Dutation"
              type="number"
              name="number_dutation"
              :isValidate="$v.dataScheduleClone.dutation.$error"
              :v="$v.dataScheduleClone.dutation"
            />
          </div>
          <div class="my-3">
            <InputText
              v-model="dataScheduleClone.bufferTimeAfter"
              class="input"
              textFloat="Buffee Time After (Minute)"
              placeholder="Buffee Time After (Minute)"
              type="number"
              name="number_bufferTimeAfter"
            />
          </div>
          <div class="my-3" v-if="quantity">
            <InputText
              v-model="dataScheduleClone.quantity"
              class="input"
              textFloat="Quantity"
              placeholder="Quantity"
              type="number"
              name="number_quantity"
            />
          </div>
        </template>
      </SideBarFilter>
    </div>

    <div class="header-title">
      <h1 class="title">RoundTime</h1>
      <b-button class="btn-filter" @click="openSidebarRoundTime">
        RoundTime
      </b-button>
    </div>
    <div class="data-table">
      <TableRoundTime
        :items="itemsRoundTime"
        :fields="fieldsRoundTime"
        :isBusy="isBusyRoundTime"
        :rows="rowsRoundTime"
        :filter="filterRoundTime"
        :pageOptions="pageOptionsRoundTime"
        @filterPage="filterPageRoundTime"
        @editRoundtime="editRoundtime"
      />
      <SideBarFilter
        ref="sidebarRoundTime"
        :title="
          formRoundTime?.eventDateGroupId
            ? 'Edit Round Time'
            : 'Create Round Time'
        "
        :textSubmit="formRoundTime?.eventDateGroupId ? 'Update' : 'Save'"
        :filter="formRoundTime"
        :hideStatusFilter="false"
        :hideSearchBar="true"
        @searchAll="insertEventRoundTime"
        @clearForm="clearFormRoundTime"
        handleSideBar
        hideFilter
        :isLoading="isLoading"
        @clearLoading="isLoading = false"
      >
        <template v-slot:filter-option>
          <div class="my-3">
            <InputText
              v-model="formRoundTime.name"
              class="input"
              textFloat="Name"
              placeholder="Name"
              type="text"
              name="text_name"
            />
            <!-- isRequired
              :isValidate="$v.formRoundTime.name.$error"
              :v="$v.formRoundTime.name" -->
          </div>
          <div class="my-3" v-if="isSidebar == 1">
            <InputDropdownCheckbox
              name="round"
              textFloat="Day"
              :value="formRoundTime.dayOfWeekId"
              :options="optionRoundTime"
              :valueSelectedAll="checkDayAll"
              valueField="id"
              textField="name"
              @onDataChangeCheckAll="(val) => (checkDayAll = val)"
              @onDataChange="(val) => (formRoundTime.dayOfWeekId = val)"
              isRequired
              :isValidate="$v.formRoundTime.dayOfWeekId.$error"
              :v="$v.formRoundTime.dayOfWeekId"
            />
            <!-- @onDataChange="(val) => (formRoundTime.dayOfWeekId = val)" -->
          </div>
          <div class="my-3">
            <b-row>
              <b-col cols="6">
                <!-- <InputText
                  v-model="formRoundTime.timeFrom"
                  class="input"
                  textFloat="Time From"
                  placeholder="Time From"
                  type="time old"
                  name="text_timeFrom"
                  isRequired
                  :isValidate="$v.formRoundTime.timeFrom.$error"
                  :v="$v.formRoundTime.timeFrom"
                /> -->
                <p class="font-15">
                  Time From
                  <span class="text-error">*</span>
                </p>
                <div
                  class="input-container mr-2"
                  :class="$v.formRoundTime.timeFrom.$error && 'error'"
                >
                  <datetime
                    :input-style="styleDatetime"
                    v-model="formRoundTime.timeFrom"
                    type="time"
                    format="HH:mm"
                    value-zone="local"
                    placeholder="Time From"
                    ref="formRoundTime"
                  >
                  </datetime>
                  <div
                    class="icon-container text-right"
                    @click="$refs.formRoundTime.isOpen = true"
                  >
                    <b-icon
                      icon="clock-fill"
                      class="float-right"
                      color="#B41BB4"
                    ></b-icon>
                  </div>
                </div>
                <p
                  class="text-error m-0"
                  v-if="$v.formRoundTime.timeFrom.$error"
                >
                  Please select time
                </p>
              </b-col>
              <b-col cols="6">
                <InputText
                  v-model="formRoundTime.numberOfRound"
                  class="input"
                  textFloat="Number of round"
                  placeholder="0"
                  type="number"
                  name="numberRoundTime"
                  isRequired
                  :isValidate="$v.formRoundTime.numberOfRound.$error"
                  :v="$v.formRoundTime.numberOfRound"
                />
                <p class="m-0 text-error" v-if="errorRoundTime.status">
                  Value must more than {{ errorRoundTime.value - 1 }}
                </p>
              </b-col>
            </b-row>
          </div>
          <div class="my-3">
            <b-form-group label="Ticket" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="formRoundTime.productId"
                :options="optionTicket"
                :aria-describedby="ariaDescribedby"
                value-field="productId"
                text-field="productName"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>
            <p class="m-0 text-nodata" v-if="optionTicket.length == 0">
              No data
            </p>
            <p class="text-error m-0" v-if="$v.formRoundTime.productId.$error">
              Please select ticket
            </p>
          </div>
          <div class="mt-3 mb-3">
            <b-form-checkbox
              v-model="formRoundTime.isActive"
              switch
              :value="1"
              :unchecked-value="0"
            >
              Active
            </b-form-checkbox>
          </div>
        </template>
      </SideBarFilter>
    </div>
    <div class="div-divide"></div>

    <div class="" v-show="$isEventGroup !== true && quantity !== true">
      <div class="header-title">
        <h1 class="title">Custom</h1>
        <b-button class="btn-filter" @click="openSidebarCustom">
          Custom
        </b-button>
      </div>
      <div class="data-table">
        <TableCustom
          :items="itemsCustom"
          :fields="fieldsCustom"
          :isBusy="isBusyCustom"
          :rows="rowsCustom"
          :filter="filterCustom"
          :pageOptions="pageOptionsCustom"
          @filterPage="filterPageCustom"
          @editCustom="editCustom"
        />
        <SideBarFilter
          ref="sidebarCustom"
          :title="isEditCustom ? 'Custom Edit' : 'Custom'"
          :textSubmit="isEditCustom ? 'Update' : 'Save'"
          :filter="formCustom"
          :hideStatusFilter="false"
          :hideSearchBar="true"
          @searchAll="insertEventCustom"
          @clearForm="clearFormCustom"
          handleSideBar
          hideFilter
          :isLoading="isLoading"
          @clearLoading="isLoading = false"
        >
          <template v-slot:filter-option>
            <div class="my-3">
              <strong>
                Options
                <span class="text-error">*</span>
              </strong>

              <b-form-group class="mt-2 mb-0">
                <b-form-radio-group
                  v-model="formCustom.specialPriceManageTypeId"
                  :options="optionRadioCustom"
                  stacked
                  :disabled="isEditCustom"
                ></b-form-radio-group>
              </b-form-group>
              <p
                class="m-0 text-error"
                v-if="$v.formCustom.specialPriceManageTypeId.$error"
              >
                Please select option
              </p>
            </div>
            <div v-if="formCustom.specialPriceManageTypeId == 1">
              <div class="my-3" v-if="isSidebar == 2">
                <InputDropdownCheckbox
                  ref="inputDayjustround"
                  name="names"
                  textFloat="Day"
                  :value="formCustom.dayOfWeekId"
                  :options="optionRoundTime"
                  :valueSelectedAll="checkDayAllCustom"
                  valueField="id"
                  textField="name"
                  @onDataChangeCheckAll="(val) => (checkDayAllCustom = val)"
                  @onDataChange="(val) => (formCustom.dayOfWeekId = val)"
                  isRequired
                  :isValidate="$v.formCustom.dayOfWeekId.$error"
                  :v="$v.formCustom.dayOfWeekId"
                />
              </div>

              <div class="my-3">
                <b-row>
                  <b-col cols="6">
                    <!-- <InputText
                    v-model="formCustom.timeFrom"
                    class="input without_ampm"
                    textFloat="Time From"
                    placeholder="Time From"
                    type="time old"
                    name="time_timeFrom"
                    isRequired
                    :isValidate="$v.formCustom.timeFrom.$error"
                    :v="$v.formCustom.timeFrom"
                  /> -->
                    <p class="font-15">
                      Time From
                      <span class="text-error">*</span>
                    </p>
                    <div
                      class="input-container mr-2"
                      :class="$v.formCustom.timeFrom.$error && 'error'"
                    >
                      <datetime
                        :input-style="styleDatetime"
                        v-model="formCustom.timeFrom"
                        type="time"
                        format="HH:mm"
                        value-zone="local"
                        placeholder="Time From"
                        ref="formCustom"
                      >
                      </datetime>
                      <div
                        class="icon-container text-right"
                        @click="$refs.formCustom.isOpen = true"
                      >
                        <b-icon
                          icon="clock-fill"
                          class="float-right"
                          color="#B41BB4"
                        ></b-icon>
                      </div>
                    </div>
                    <p
                      class="text-error m-0"
                      v-if="$v.formCustom.timeFrom.$error"
                    >
                      Please select time
                    </p>
                  </b-col>
                  <b-col cols="6">
                    <InputText
                      v-model="formCustom.numberOfRound"
                      class="input"
                      textFloat="Number of round"
                      placeholder="0"
                      type="number"
                      name="number_numberOfRounds"
                      isRequired
                      :isValidate="$v.formCustom.numberOfRound.$error"
                      :v="$v.formCustom.numberOfRound"
                    />

                    <p class="m-0 text-error" v-if="errorCustom.status">
                      Value must more than {{ errorCustom.value }}
                    </p>
                  </b-col>
                </b-row>
              </div>
              <div class="my-3">
                <div class="font-weight-bold mb-2">
                  From Datetime
                  <span class="text-error">*</span>
                </div>
                <div :class="['input-container']">
                  <datetime
                    v-model="formCustom.fromDateTime"
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy"
                    value-zone="local"
                    placeholder="Please Select Date"
                    ref="fromDatetime"
                  >
                    <!-- :max-datetime="maxDate" -->
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.fromDatetime.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer g-text-gold"
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <p
                  class="m-0 text-error"
                  v-if="$v.formCustom.fromDateTime.$error"
                >
                  Please select date
                </p>
              </div>
              <div class="my-3">
                <div class="font-weight-bold mb-2">To Datetime</div>
                <div :class="['input-container']">
                  <datetime
                    v-model="formCustom.toDateTime"
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy"
                    value-zone="local"
                    placeholder="Please Select Date"
                    ref="toDateTime"
                  >
                    <!-- :max-datetime="maxDate" -->
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.toDateTime.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer g-text-gold"
                      color="#B41BB4"
                    />
                  </div>
                </div>
              </div>
              <div class="my-3">
                <strong class="mb-2">Ticket</strong>
                <b-form-checkbox-group
                  v-model="formCustom.productId"
                  id="tickets2"
                  name="tickets2"
                  stacked
                >
                  <b-form-checkbox
                    class="mb-2"
                    v-for="(item, index) in optionTicket"
                    :key="index"
                    :value="item.productId"
                    switch
                    :id="`tickets2${item.productId}`"
                  >
                    <p class="m-0">
                      {{ item.productName }}
                    </p>
                    <div class="box-ticket">
                      <b-form-input
                        class="handleprice"
                        type="number"
                        placeholder="0"
                        v-model="item.newprice"
                      />
                      <p class="m-0 ml-2">Currently Price : {{ item.price }}</p>
                    </div>
                  </b-form-checkbox>
                </b-form-checkbox-group>
                <p class="m-0 text-nodata" v-if="optionTicket.length == 0">
                  No data
                </p>
                <p class="m-0 text-error" v-if="$v.formCustom.productId.$error">
                  Please select ticket
                </p>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <!--  -->
            <div v-if="formCustom.specialPriceManageTypeId == 3">
              <div class="my-3" v-if="isSidebar == 2">
                <InputSelect
                  title="Round"
                  name="type_productPriceManageGroupId"
                  isRequired
                  class="mt-2"
                  v-model="formCustom.productPriceManageGroupId"
                  v-bind:options="optionCustom"
                  valueField="productPriceManageGroupId"
                  textField="productPriceManageGroupName"
                  @onDataChange="
                    (val) => (formCustom.productPriceManageGroupId = val)
                  "
                  :isValidate="$v.formCustom.productPriceManageGroupId.$error"
                  :v="$v.formCustom.productPriceManageGroupId"
                >
                  <template v-slot:option-first>
                    <b-form-select-option :value="null" disabled>
                      -- Select Round --
                    </b-form-select-option>
                  </template>
                </InputSelect>
              </div>
              <div class="my-3">
                <div class="font-weight-bold mb-2">
                  From Datetime
                  <span class="text-error">*</span>
                </div>
                <div :class="['input-container']">
                  <datetime
                    v-model="formCustom.fromDateTime"
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy"
                    value-zone="local"
                    placeholder="Please Select Date"
                    ref="fromDatetime"
                  >
                    <!-- :max-datetime="maxDate" -->
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.fromDatetime.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer g-text-gold"
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <p
                  class="m-0 text-error"
                  v-if="$v.formCustom.fromDateTime.$error"
                >
                  Please select date
                </p>
              </div>
              <div class="my-3">
                <div class="font-weight-bold mb-2">To Datetime</div>
                <div :class="['input-container']">
                  <datetime
                    v-model="formCustom.toDateTime"
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy"
                    value-zone="local"
                    placeholder="Please Select Date"
                    ref="toDateTime"
                  >
                    <!-- :max-datetime="maxDate" -->
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.toDateTime.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer g-text-gold"
                      color="#B41BB4"
                    />
                  </div>
                </div>
              </div>
              <div class="my-3" v-if="isSidebar == 2">
                <InputDropdownCheckbox
                  ref="inputDayjustround"
                  name="dayOfWeek"
                  textFloat="Day"
                  :value="formCustom.dayOfWeekId"
                  :options="optionCustomDay"
                  :valueSelectedAll="checkDayAllCustomDay"
                  valueField="dayOfWeekId"
                  textField="dayOfWeek"
                  @onDataChangeCheckAll="(val) => (checkDayAllCustomDay = val)"
                  @onDataChange="(val) => (formCustom.dayOfWeekId = val)"
                  isRequired
                  :isValidate="$v.formCustom.dayOfWeekId.$error"
                  :v="$v.formCustom.dayOfWeekId"
                />
              </div>
              <div class="my-3" v-if="isSidebar == 2">
                <InputDropdownCheckbox
                  ref="inputRoundTime"
                  name="fullTimeroundTimeEventId"
                  textFloat="Time"
                  :value="formCustom.roundTimeId"
                  :options="optionCustomTime"
                  :valueSelectedAll="checkDayAllCustomTime"
                  valueField="roundTimeEventId"
                  textField="fullTime"
                  @onDataChangeCheckAll="(val) => (checkDayAllCustomTime = val)"
                  @onDataChange="(val) => (formCustom.roundTimeId = val)"
                  isRequired
                  :isValidate="$v.formCustom.roundTimeId.$error"
                  :v="$v.formCustom.roundTimeId"
                />
              </div>
              <div class="my-3">
                <strong class="mb-2">Ticket</strong>
                <b-form-checkbox-group
                  v-model="formCustom.productId"
                  id="tickets3"
                  name="tickets3"
                  stacked
                >
                  <b-form-checkbox
                    class="mb-2"
                    v-for="(item, index) in optionCustomTicket"
                    :key="index"
                    :value="item.productId"
                    switch
                    :id="`tickets3${item.productId}`"
                  >
                    <p class="m-0">
                      {{ item.productName }}
                    </p>
                    <div class="box-ticket">
                      <b-form-input
                        class="handleprice"
                        type="number"
                        placeholder="0"
                        v-model="item.newprice"
                      />
                      <p class="m-0 ml-2">Currently Price : {{ item.price }}</p>
                    </div>
                  </b-form-checkbox>
                </b-form-checkbox-group>
                <p
                  class="m-0 text-nodata"
                  v-if="optionCustomTicket.length == 0"
                >
                  No data
                </p>
                <p class="m-0 text-error" v-if="$v.formCustom.productId.$error">
                  Please select ticket
                </p>
              </div>
            </div>

            <div class="mt-3 mb-3">
              <b-form-checkbox
                v-model="formCustom.isActive"
                switch
                :value="1"
                :unchecked-value="0"
              >
                Active
              </b-form-checkbox>
            </div>
          </template>
        </SideBarFilter>
      </div>
      <div class="div-divide"></div>
    </div>

    <div class="header-title">
      <h1 class="title">Close</h1>
      <b-button class="btn-filter" @click="openSidebarClose"> Close </b-button>
    </div>
    <div class="data-table">
      <TableClose
        :items="itemsClose"
        :fields="fieldsClose"
        :isBusy="isBusyClose"
        :rows="rowsClose"
        :filter="filterClose"
        :pageOptions="pageOptionsClose"
        @filterPage="filterPageClose"
        @editClose="editClose"
      />
      <SideBarFilter
        ref="sidebarClose"
        :title="isEditClose ? 'Close Edit' : 'Close'"
        :textSubmit="isEditClose ? 'Update' : 'Save'"
        :filter="formClose"
        :hideStatusFilter="false"
        :hideSearchBar="true"
        @searchAll="insertEventClose"
        @clearForm="clearFormClose"
        handleSideBar
        hideFilter
        :isLoading="isLoading"
        @clearLoading="isLoading = false"
      >
        <template v-slot:filter-option>
          <div class="my-3">
            <div class="font-weight-bold mb-2">
              From Datetime
              <span class="text-error">*</span>
            </div>
            <div :class="['input-container']">
              <datetime
                v-model="formClose.fromDateTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="fromDatetime"
              >
                <!-- :max-datetime="maxDate" -->
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.fromDatetime.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
            <p class="m-0 text-error" v-if="$v.formClose.fromDateTime.$error">
              Please select date
            </p>
          </div>
          <div class="my-3">
            <div class="font-weight-bold mb-2">To Datetime</div>
            <div :class="['input-container']">
              <datetime
                v-model="formClose.toDateTime"
                :input-style="styleDatetime"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="toDateTime"
              >
                <!-- :max-datetime="maxDate" -->
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.toDateTime.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="my-3">
            <InputDropdownCheckbox
              name="dayClose"
              textFloat="Day"
              :value="formClose.dayOfWeekId"
              :options="optionRoundTime"
              :valueSelectedAll="checkDayCloseAllDay"
              valueField="id"
              textField="name"
              @onDataChangeCheckAll="(val) => (checkDayCloseAllDay = val)"
              @onDataChange="(val) => (formClose.dayOfWeekId = val)"
              isRequired
              :isValidate="$v.formClose.dayOfWeekId.$error"
              :v="$v.formClose.dayOfWeekId"
            />
          </div>
          <!-- <div class="my-3">
            <InputDropdownCheckbox
              ref="inputRoundTimeClose"
              name="timeClose"
              textFloat="Time"
              :value="formClose.roundTimeId"
              :options="optionClose"
              :valueSelectedAll="checkDayCloseAllTime"
              valueField="roundTimeEventId"
              textField="fullTime"
              @onDataChangeCheckAll="(val) => (checkDayCloseAllTime = val)"
              @onDataChange="(val) => (formClose.roundTimeId = val)"
              isRequired
              :isValidate="$v.formClose.roundTimeId.$error"
              :v="$v.formClose.roundTimeId"
            />
          </div> -->
          <div class="my-3">
            <b-row>
              <b-col cols="6">
                <!-- <InputText
                  v-model="formClose.closeStartTime"
                  class="input"
                  textFloat="Start Time"
                  placeholder="Start Time"
                  type="time old"
                  name="text_closeStartTime"
                  isRequired
                  :isValidate="$v.formClose.closeStartTime.$error"
                  :v="$v.formClose.closeStartTime"
                /> -->
                <p class="font-15">
                  Start Time
                  <span class="text-error">*</span>
                </p>
                <div
                  class="input-container mr-2"
                  :class="$v.formClose.closeStartTime.$error && 'error'"
                >
                  <datetime
                    :input-style="styleDatetime"
                    v-model="formClose.closeStartTime"
                    type="time"
                    format="HH:mm"
                    value-zone="local"
                    placeholder="Time From"
                    ref="closeStartTime"
                  >
                  </datetime>
                  <div
                    class="icon-container text-right"
                    @click="$refs.closeStartTime.isOpen = true"
                  >
                    <b-icon
                      icon="clock-fill"
                      class="float-right"
                      color="#B41BB4"
                    ></b-icon>
                  </div>
                </div>
                <p
                  class="text-error m-0"
                  v-if="$v.formClose.closeStartTime.$error"
                >
                  Please select time
                </p>
              </b-col>
              <b-col cols="6">
                <!-- <InputText
                  v-model="formClose.closeEndTime"
                  class="input"
                  textFloat="End Time"
                  placeholder="End Time"
                  type="time old"
                  name="text_closeEndTime"
                  isRequired
                  :isValidate="$v.formClose.closeEndTime.$error"
                  :v="$v.formClose.closeEndTime"
                /> -->
                <p class="font-15">
                  End Time
                  <span class="text-error">*</span>
                </p>
                <div
                  class="input-container mr-2"
                  :class="$v.formClose.closeEndTime.$error && 'error'"
                >
                  <datetime
                    :input-style="styleDatetime"
                    v-model="formClose.closeEndTime"
                    type="time"
                    format="HH:mm"
                    value-zone="local"
                    placeholder="Time From"
                    ref="closeEndTime"
                  >
                  </datetime>
                  <div
                    class="icon-container text-right"
                    @click="$refs.closeEndTime.isOpen = true"
                  >
                    <b-icon
                      icon="clock-fill"
                      class="float-right"
                      color="#B41BB4"
                    ></b-icon>
                  </div>
                </div>
                <p
                  class="text-error m-0"
                  v-if="$v.formClose.closeEndTime.$error"
                >
                  Please select time
                </p>
              </b-col>
            </b-row>
          </div>
          <div class="my-3">
            <strong>
              Status
              <span class="text-error">*</span>
            </strong>
            <b-form-group class="mt-2 mb-0">
              <b-form-radio-group
                v-model="formClose.specialPriceManageTypeId"
                :options="optionStatus"
                stacked
              ></b-form-radio-group>
            </b-form-group>
            <p
              class="m-0 text-error"
              v-if="$v.formClose.specialPriceManageTypeId.$error"
            >
              Please select status
            </p>
          </div>
          <div class="my-3" v-if="formClose.specialPriceManageTypeId == 2">
            <strong> Ticket </strong>
            <b-form-checkbox-group
              v-model="formClose.productId"
              id="tickets4"
              name="tickets4"
              stacked
            >
              <b-form-checkbox
                class="mb-2"
                v-for="(item, index) in optionTicket"
                :key="index"
                :value="item.productId"
                switch
                :id="`tickets4${item.productId}`"
              >
                <p class="m-0">
                  {{ item.productName }}
                </p>
              </b-form-checkbox>
            </b-form-checkbox-group>
            <p class="m-0 text-nodata" v-if="optionTicket.length == 0">
              No data
            </p>
            <p class="m-0 text-error" v-if="$v.formClose.productId.$error">
              Please select status
            </p>
          </div>
          <div class="mt-3 mb-3">
            <b-form-checkbox
              v-model="formClose.isActive"
              switch
              :value="1"
              :unchecked-value="0"
            >
              Active
            </b-form-checkbox>
          </div>
        </template>
      </SideBarFilter>
    </div>
    <div class="div-divide"></div>
    <allotment-preview />
  </div>
</template>

<script>
import TableRoundTime from "../component/tap3/TableRoundTime.vue";
import TableCustom from "../component/tap3/TableCustom.vue";
import TableClose from "../component/tap3/TableClose.vue";

import InputDropdownCheckbox from "@/components/inputs/InputDropdownCheckbox.vue";
import AllotmentPreview from "./tap3/AllotmentPreview.vue";
import { required, minValue, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    TableRoundTime,
    TableCustom,
    TableClose,
    InputDropdownCheckbox,
    AllotmentPreview,
  },
  props: {
    previewOrPublish: {
      type: Number,
      require: false,
    },
    quantity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // state schedule
      dataSchedule: null,
      dataScheduleClone: null,

      // ? state table roundTime =========================================
      formRoundTime: {
        name: "",
        dayOfWeekId: [],
        timeFrom: "",
        numberOfRound: 0,
        productId: [],
        isActive: 1,
      },

      checkDayAll: false,
      errorRoundTime: {
        status: false,
        value: null,
      },

      optionRoundTime: [],
      optionTicket: [],

      itemsRoundTime: [],
      filterRoundTime: {
        skip: 1,
        take: 10,
        page: 1,
      },
      fieldsRoundTime: [
        {
          key: "productPriceManageGroupName",
          label: "Name",
          class: "w-50px text-nowrap",
        },
        {
          key: "productPriceManageGroupStartTime",
          label: "Open Date",
          class: "w-50px text-nowrap",
        },
        {
          key: "productPriceManageGroupRound",
          label: "Number of Round",
          class: "w-50px text-nowrap",
        },
        {
          key: "isActive",
          label: "Active",
          class: "w-100px text-nowrap",
        },
        // { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      isBusyRoundTime: false,
      rowsRoundTime: 0,
      pageOptionsRoundTime: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // ? state table custom =========================================

      formCustom: {
        specialPriceManageTypeId: null,
        productPriceManageGroupId: null,
        fromDateTime: "",
        timeFrom: "",
        toDateTime: null,
        dayOfWeekId: [],
        roundTimeId: [],
        productId: [],
        numberOfRound: 0,
        isActive: 1,
      },

      checkDayAllCustom: false,
      checkDayAllCustomDay: false,
      checkDayAllCustomTime: false,

      isEditCustom: false,
      errorCustom: {
        status: false,
        value: null,
      },

      optionCustom: [],
      optionRadioCustom: [],
      optionCustomDay: [],
      optionCustomTime: [],
      optionCustomTicket: [],

      itemsCustom: [],
      filterCustom: {
        skip: 1,
        take: 10,
        page: 1,
      },
      fieldsCustom: [
        {
          key: "specialPriceManageTypeName",
          label: "Type",
          class: "w-100px text-nowrap",
        },
        {
          key: "specialPriceManageGroupName",
          label: "Day",
          class: "w-50px text-nowrap",
        },
        {
          key: "specialPriceManageGroupStartTime",
          label: "Time",
          class: "w-50px text-nowrap",
        },
        {
          key: "specialPriceManageGroupRound",
          label: "Number of Round",
          class: "w-50px text-nowrap",
        },
        {
          key: "effectiveDt",
          label: "From",
          class: "w-100px text-nowrap",
        },
        {
          key: "expiryDt",
          label: "To",
          class: "w-100px text-nowrap",
        },
        {
          key: "isActive",
          label: "Active",
          class: "w-100px text-nowrap",
        },
        // { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      isBusyCustom: false,
      rowsCustom: 0,
      pageOptionsCustom: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // ? state table close =========================================

      formClose: {
        specialPriceManageTypeId: null,
        productPriceManageGroupId: null,
        fromDateTime: "",
        toDateTime: null,
        timeFrom: "",
        dayOfWeekId: [],
        roundTimeId: [],
        productId: [],
        isActive: 1,
        closeStartTime: null,
        closeEndTime: null,
      },

      checkDayCloseAllDay: false,
      checkDayCloseAllTime: false,

      isEditClose: false,

      optionClose: [],
      optionStatus: [
        {
          value: 1,
          text: "Close",
        },
        {
          value: 2,
          text: "Disable Ticket",
        },
      ],

      itemsClose: [],
      filterClose: {
        skip: 1,
        take: 10,
        page: 1,
      },
      fieldsClose: [
        // {
        //   key: "specialPriceManageTypeName",
        //   label: "Type",
        //   class: "w-50px text-nowrap",
        // },
        {
          key: "specialPriceManageGroupName",
          label: "Day",
          class: "w-50px text-nowrap",
        },
        // {
        //   key: "specialPriceManageGroupStartTime",
        //   label: "Time",
        //   class: "w-50px text-nowrap",
        // },
        // {
        //   key: "specialPriceManageGroupRound",
        //   label: "Number of Round",
        //   class: "w-50px text-nowrap",
        // },
        {
          key: "effectiveDt",
          label: "From",
          class: "w-100px text-nowrap",
        },
        {
          key: "expiryDt",
          label: "To",
          class: "w-100px text-nowrap",
        },
        {
          key: "closeStartTime",
          label: "Start Time",
          class: "w-100px text-nowrap",
        },
        {
          key: "closeEndTime",
          label: "End Time",
          class: "w-100px text-nowrap",
        },
        {
          key: "isActive",
          label: "Active",
          class: "w-100px text-nowrap",
        },
        // { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      isBusyClose: false,
      rowsClose: 0,
      pageOptionsClose: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },

      // ! state divide sidebar
      isSidebar: 0,

      // state toggle
      isLoading: false,
    };
  },

  validations() {
    const formRoundTime = {
      // name: { required },
      timeFrom: { required },
      dayOfWeekId: { required },
      productId: { required },
      numberOfRound: {
        required,
        minValue: minValue(1),
      },
    };

    const formCustom = {
      // both
      specialPriceManageTypeId: { required },
      dayOfWeekId: { required },
      fromDateTime: { required },
      productId: { required },

      // type 1

      timeFrom: {
        required: requiredIf(() => {
          return this.formCustom.specialPriceManageTypeId == 1;
        }),
      },
      numberOfRound: {
        required: requiredIf(() => {
          return this.formCustom.specialPriceManageTypeId == 1;
        }),
        minValue: minValue(
          this.formCustom.specialPriceManageTypeId == 1 ? 1 : 0
        ),
      },

      // type 3

      productPriceManageGroupId: {
        required: requiredIf(() => {
          return this.formCustom.specialPriceManageTypeId == 3;
        }),
      },
      roundTimeId: {
        required: requiredIf(() => {
          return this.formCustom.specialPriceManageTypeId == 3;
        }),
      },
    };

    const formClose = {
      fromDateTime: { required },
      dayOfWeekId: { required },
      // roundTimeId: { required },
      specialPriceManageTypeId: { required },
      closeStartTime: { required },
      closeEndTime: { required },
      productId: {
        required: requiredIf(() => {
          return this.formClose.specialPriceManageTypeId == 2;
        }),
      },
    };

    const dataScheduleClone = {
      dutation: { required, minValue: minValue(1) },
    };

    return {
      formRoundTime,
      formCustom,
      formClose,
      dataScheduleClone,
    };
  },
  watch: {
    "formCustom.productPriceManageGroupId"(val) {
      if (val) {
        this.getOptionDay(val);
        this.getOptionTime(val);

        this.getCustomTicket(val);
      }
    },
    "formCustom.specialPriceManageTypeId"(val) {
      if (!this.isEditCustom) {
        this.formCustom.productId = [];

        // clear special when change type
        this.formCustom.fromDateTime = "";
        this.formCustom.timeFrom = "";
        this.formCustom.toDateTime = "";
        this.formCustom.dayOfWeekId = [];
        this.formCustom.roundTimeId = [];
        this.formCustom.productId = [];
      }
    },
  },
  mounted() {
    this.getSchedule();
    this.getRoundTime();
    this.getCustom();
    this.getClose();
    this.getDayofWeek();
    this.getOptionTicket();
    this.getOptionCustomType();
    this.getOptionRoundTime();
  },
  methods: {
    async getRoundTime() {
      let { page, take } = this.filterRoundTime;

      this.isBusyRoundTime = true;

      const getData = await this.$services.event.getRoundTime(
        this.$route.params.id,
        page,
        take
      );

      if (getData.result == 1) {
        getData.detail.data = getData.detail.data.map((x) => {
          x.productPriceManageGroupStartTime = this.$services.utils.formatTime(
            x.productPriceManageGroupStartTime
          );
          return x;
        });
        this.itemsRoundTime = getData.detail.data;
        this.rowsRoundTime = getData.detail.count;
      }

      this.isBusyRoundTime = false;
    },

    async getCustom() {
      let { page, take } = this.filterCustom;

      this.isBusyCustom = true;

      const getData = await this.$services.event.getCustom(
        this.$route.params.id,
        page,
        take
      );

      if (getData.result == 1) {
        getData.detail.data = getData.detail.data.map((x) => {
          x.specialPriceManageGroupStartTime = this.$services.utils.formatTime(
            x.specialPriceManageGroupStartTime
          );
          return x;
        });

        this.itemsCustom = getData.detail.data;
        this.rowsCustom = getData.detail.count;
      }

      this.isBusyCustom = false;
    },

    async getClose() {
      let { page, take } = this.filterClose;

      this.isBusyClose = true;

      const getData = await this.$services.event.getClose(
        this.$route.params.id,
        page,
        take
      );

      if (getData.result == 1) {
        getData.detail.data = getData.detail.data.map((x) => {
          x.specialPriceManageGroupStartTime = this.$services.utils.formatTime(
            x.specialPriceManageGroupStartTime
          );
          return x;
        });
        getData.detail.data = getData.detail.data.map((x) => {
          x.closeEndTime = this.$services.utils.formatTime(x.closeEndTime);
          x.closeStartTime = this.$services.utils.formatTime(x.closeStartTime);
          return x;
        });
        this.itemsClose = getData.detail.data;
        this.rowsClose = getData.detail.count;
      }

      this.isBusyClose = false;
    },

    async getSchedule() {
      const getData = await this.$services.event.getSchedule(
        this.$route.params.id,
        this.quantity
      );

      if (getData.result == 1) {
        this.dataSchedule = getData.detail;
        this.dataScheduleClone = { ...getData.detail };
      }
    },

    async getDayofWeek() {
      const getData = await this.$services.master.getOptionDayofWeek();

      if (getData.result == 1) {
        this.optionRoundTime = getData.detail;
      }
    },

    async getOptionTicket() {
      const getData = await this.$services.master.getOptionTicket(
        this.$route.params.id
      );

      if (getData.result == 1) {
        this.optionTicket = getData.detail.map((x) => {
          x.newprice = 0;
          return x;
        });
      }
    },

    async insertEventRoundTime(obj) {
      this.$v.formRoundTime.$touch();

      if (this.$v.formRoundTime.$error) {
        return;
      }

      if (obj.eventDateGroupId) {
        this.errorRoundTime = {
          status: false,
          value: null,
        };

        const isCheckNumber = this.itemsRoundTime.find(
          (x) => x.productPriceManageGroupId == obj.productPriceManageGroupId
        );

        if (
          isCheckNumber.productPriceManageGroupRound > obj.numberOfRound &&
          this.previewOrPublish == 1
        ) {
          this.errorRoundTime = {
            status: true,
            value: isCheckNumber.productPriceManageGroupRound,
          };

          return;
        }

        obj.timeFrom = `${this.convertTimeHHMM(obj.timeFrom)}`;
        this.updateEventRoundTime(obj);

        return;
      }

      obj.timeFrom = `${this.convertTimeHHMM(obj.timeFrom)}:00`;

      this.isLoading = true;

      const insert = await this.$services.event.insertProductPriceManage(
        this.$route.params.id,
        obj
      );

      if (insert.result == 1) {
        this.getRoundTime();
        this.getOptionRoundTime();
      }
      this.clearFormRoundTime();
      this.closeSidebarRoundTime();
      this.openModalAlertText(insert.detail.message);
    },

    async getOptionRoundTime() {
      const getData = await this.$services.master.getOptionRoundTime(
        this.$route.params.id
      );

      if (getData.result == 1) {
        this.optionCustom = getData.detail;
      }
    },

    async getOptionCustomType() {
      const getData = await this.$services.master.getOptionCustomType();

      if (getData.result == 1) {
        this.optionRadioCustom = getData.detail.map((x) => {
          return {
            value: x.id,
            text: x.name,
            ...x,
          };
        });
      }
    },

    async insertEventCustom(obj) {
      this.$v.formCustom.$touch();

      if (this.$v.formCustom.$error) {
        return;
      }

      let {
        specialPriceManageTypeId,
        productPriceManageGroupId,
        fromDateTime,
        toDateTime,
        dayOfWeekId,
        isActive,
        roundTimeId,
        productId,
        timeFrom,
        numberOfRound,
      } = obj;

      const body = {
        timeFrom:
          specialPriceManageTypeId == 3
            ? null
            : `${this.convertTimeHHMM(timeFrom)}:00`,
        numberOfRound: specialPriceManageTypeId == 3 ? 0 : numberOfRound,
        productPriceManageGroupId:
          productPriceManageGroupId == null ? 0 : productPriceManageGroupId,
        fromDateTime,
        toDateTime: toDateTime ? toDateTime : null,
        dayOfWeekId,
        roundTime: this.convertRoundTime(roundTimeId),
        product: this.convertProduct(productId, specialPriceManageTypeId),
        isActive,
        status: this.isSidebar == 2 ? 0 : null,
      };

      if (this.isEditCustom) {
        // edit
        this.errorCustom = {
          status: false,
          value: null,
        };

        const isCheckNumber = this.itemsCustom.find(
          (x) => x.specialPriceManageGroupId == obj.specialPriceManageGroupId
        );

        if (
          isCheckNumber.specialPriceManageGroupRound > obj.numberOfRound &&
          this.previewOrPublish == 1
        ) {
          this.errorCustom = {
            status: true,
            value: isCheckNumber.specialPriceManageGroupRound,
          };

          return;
        }
        // update
        this.updateCustomAndClose(body, obj, "custom");
      } else {
        // insert
        this.insertCustom(specialPriceManageTypeId, body);
      }
    },

    async editRoundtime(productPriceId) {
      const getData = await this.$services.event.getRoundtimeById(
        this.$route.params.id,
        productPriceId
      );

      if (getData.result == 1) {
        const oldVlaue = {
          name: getData.detail.productPriceManageGroupName,
          timeFrom: this.convertTime(
            getData.detail.productPriceManageGroupStartTime
          ),
          numberOfRound: getData.detail.productPriceManageGroupRound,
        };
        this.formRoundTime = { ...oldVlaue, ...getData.detail };

        if (getData.detail.dayOfWeekId.length == 7) {
          this.checkDayAll = true;
        }

        this.openSidebarRoundTime();
      }
    },

    async updateEventRoundTime(obj) {
      let {
        productPriceManageGroupId,
        name,
        dayOfWeekId,
        timeFrom,
        numberOfRound,
        productId,
        roundTimeEventId,
        eventDateGroupId,
        isActive,
      } = obj;

      const body = {
        name,
        dayOfWeekId,
        timeFrom: `${timeFrom}:00`,
        numberOfRound,
        productId,
        roundTimeEventId,
        eventDateGroupId,
        isActive,
      };

      this.isLoading = true;

      const update = await this.$services.event.updateProductPriceManage(
        this.$route.params.id,
        productPriceManageGroupId,
        body
      );

      if (update.result == 1) {
        this.getRoundTime();
      }
      this.clearFormRoundTime();
      this.closeSidebarRoundTime();
      this.openModalAlertText(update.detail.message);
    },

    async getOptionDay(productPriceId) {
      const getData = await this.$services.master.getOptionDay(
        this.$route.params.id,
        productPriceId
      );

      if (getData.result == 1) {
        this.optionCustomDay = getData.detail.dayOfWeekId;
        this.formCustom.dayOfWeekId = getData.detail.dayOfWeekId.map(
          (x) => x.dayOfWeekId
        );
        this.checkDayAllCustomDay = true;
      }
    },

    async getOptionTime(productPriceId) {
      const getData = await this.$services.master.getOptionTime(
        this.$route.params.id,
        productPriceId
      );

      if (getData.result == 1) {
        this.optionCustomTime = getData.detail.map((x) => {
          x.fullTime = `${this.$services.utils.formatTime(
            x.timeStart
          )} - ${this.$services.utils.formatTime(x.timeEnd)}`;
          return x;
        });
      }
    },

    async getCustomTicket(productPriceId) {
      const getoptionevent = await this.$services.master.getOptionTicket(
        this.$route.params.id
      );

      if (getoptionevent.result == 1) {
        this.optionCustomTicket = getoptionevent.detail.map((x) => {
          x.newprice = 0;
          return x;
        });
      }

      // this.optionCustomTicket = finalOption;
    },

    async insertCustom(specialPriceManageTypeId, body) {
      body.toDateTime = body.toDateTime ? body.toDateTime : null;

      this.isLoading = true;

      const insert = await this.$services.event.insertCustom(
        this.$route.params.id,
        specialPriceManageTypeId,
        body
      );

      if (insert.result == 1) {
        this.getCustom();
      }
      this.closeSidebarCustom();
      this.openModalAlertText(insert.detail.message);
    },

    async insertClose(body) {
      this.isLoading = true;

      const insert = await this.$services.event.insertCloseManage(
        this.$route.params.id,
        2,
        body
      );

      if (insert.result == 1) {
        this.getClose();
      }
      this.closeSidebarClose();
      this.openModalAlertText(insert.detail.message);
    },

    async getOptionRoundTimeEvent() {
      const getData = await this.$services.master.getOptionRoundTimeEvent(
        this.$route.params.id
      );

      if (getData.result == 1) {
        this.optionClose = getData.detail.map((x) => {
          x.fullTime = `${this.$services.utils.formatTime(
            x.timeStart
          )} - ${this.$services.utils.formatTime(x.timeEnd)}`;
          return x;
        });
      }
    },

    async insertEventClose(obj) {
      this.$v.formClose.$touch();

      if (this.$v.formClose.$error) {
        return;
      }

      let {
        specialPriceManageTypeId,
        productPriceManageGroupId,
        fromDateTime,
        toDateTime,
        dayOfWeekId,
        isActive,
        roundTimeId,
        productId,
        numberOfRound,
        closeStartTime,
        closeEndTime,
      } = obj;

      const body = {
        numberOfRound: 0,
        productPriceManageGroupId: 0,
        fromDateTime,
        toDateTime: toDateTime ? toDateTime : null,
        dayOfWeekId,
        roundTime: this.convertRoundTime(roundTimeId, true),
        product:
          specialPriceManageTypeId == 1
            ? []
            : this.convertProduct(productId, specialPriceManageTypeId),
        isActive,
        status: specialPriceManageTypeId,
        closeStartTime: `${this.convertTimeHHMM(closeStartTime)}:00`,
        closeEndTime: `${this.convertTimeHHMM(closeEndTime)}:00`,
      };

      if (this.isEditClose) {
        // edit
        body.productPriceManageGroupId = productPriceManageGroupId;
        // this.updateCustomAndClose(body, obj, "close");
        this.updateCloseManage(body, obj, "close");
      } else {
        // insert
        this.insertClose(body);
      }
    },

    async updateCloseManage(body, obj, type) {
      let { productPriceManageGroupId, specialPriceManageTypeId } = obj;
      const update = await this.$services.event.updateCloseManage(
        this.$route.params.id,
        specialPriceManageTypeId,
        productPriceManageGroupId,
        body
      );

      if (update.result == 1) {
        this.getClose();
      }

      this.closeSidebarClose();
      this.openModalAlertText(update.detail.message);
    },

    async editCustom(specialPriceManageGroupId) {
      this.isEditCustom = true;

      const getData =
        await this.$services.event.getSpecialProductPriceManageById(
          specialPriceManageGroupId
        );

      if (getData.result == 1) {
        const detail = getData.detail;

        await this.getCustomTicket(detail.productPriceManageGroupId);
        this.openSidebarCustom();

        this.formCustom = {
          specialPriceManageTypeId: detail.specialPriceManageTypeId,
          productPriceManageGroupId: detail.productPriceManageGroupId,
          fromDateTime: detail.effectiveDt,
          timeFrom: this.convertTime(detail.specialPriceManageGroupStartTime),
          toDateTime: detail.expiryDt,
          dayOfWeekId: detail.dayOfWeekId,
          roundTimeId: detail.roundTimeEventId,
          productId: detail.product.map((x) => x.productId),
          numberOfRound: detail.specialPriceManageGroupRound,
          isActive: detail.isActive,
          ...detail,
        };

        if (detail.specialPriceManageTypeId == 1) {
          this.optionTicket = this.optionTicket.map((x) => {
            const find = detail.product.find((y) => {
              return x.productId == y.productId;
            });

            x.newprice = find.price;

            return x;
          });
        } else {
          setTimeout(() => {
            this.optionCustomTicket = this.optionCustomTicket.map((x) => {
              const find = detail.product.find((y) => {
                return x.productId == y.productId;
              });

              x.newprice = find.price;

              return x;
            });
          }, 500);
        }

        setTimeout(() => {
          this.$refs.inputDayjustround.handleNewText();

          if (detail.specialPriceManageTypeId == 3) {
            this.$refs.inputRoundTime.handleNewTime();
          }
        }, 500);
      }
    },

    async editClose(specialPriceManageGroupId) {
      this.isEditClose = true;

      const getData = await this.$services.event.getCloseManageById(
        specialPriceManageGroupId
      );

      if (getData.result == 1) {
        const detail = getData.detail;

        // this.formClose.specialPriceManageTypeId = detail.status;
        this.formClose.specialPriceManageTypeId =
          detail.specialPriceManageTypeId;
        this.formClose.productPriceManageGroupId = specialPriceManageGroupId;
        this.formClose.fromDateTime = detail.effectiveDt;
        this.formClose.toDateTime = detail.expiryDt;
        this.formClose.roundTimeId = detail.roundTimeEventId || [];
        this.formClose.dayOfWeekId = detail.dayOfWeekId;
        this.formClose.productId = detail.product.map((x) => x.productId);
        this.formClose.isActive = detail.isActive;

        this.formClose.closeStartTime = this.convertTime(detail.closeStartTime);
        this.formClose.closeEndTime = this.convertTime(detail.closeEndTime);
        this.formClose.specialPriceManageTypeId = detail.status;

        // setTimeout(() => {
        //   this.$refs.inputRoundTimeClose.handleNewTime();
        // }, 500);

        this.openSidebarClose();
      }
    },

    async updateCustomAndClose(body, obj, typeEvent) {
      let {
        specialPriceManageTypeId,
        specialPriceManageGroupId,
        productPriceManageGroupId,
      } = obj;

      let specialPrice =
        typeEvent == "custom"
          ? specialPriceManageGroupId
          : productPriceManageGroupId;

      this.isLoading = true;

      const editclose = await this.$services.event.updateCustomAndClose(
        this.$route.params.id,
        specialPriceManageTypeId,
        specialPrice,
        body
      );

      if (editclose.result == 1) {
        if (typeEvent == "custom") {
          this.getCustom();
          this.closeSidebarCustom();
        } else {
          this.getClose();
          this.closeSidebarClose();
        }
      }

      this.openModalAlertText(editclose.detail.message);
    },

    async editSchedule(obj) {
      this.$v.dataScheduleClone.$touch();

      if (this.$v.dataScheduleClone.$error) {
        return;
      }

      const body = {
        ...obj,
        bufferTimeAfter: !obj.bufferTimeAfter ? 0 : obj.bufferTimeAfter,
        endReserveDate: !obj.endReserveDate ? null : obj.endReserveDate,
        startReserveDate: !obj.startReserveDate ? null : obj.startReserveDate,
        quantity:
          !obj.quantity || this.quantity === false ? null : obj.quantity,
      };

      this.isLoading = true;

      const edit = await this.$services.event.updateSchedule(
        this.$route.params.id,
        body
      );

      if (edit.result == 1) {
        this.getSchedule();
      }
      this.closeSidebarEditSchedule();
      this.openModalAlertText(edit.detail.message);
    },

    convertRoundTime(arr, boolean) {
      const cloneCustomTime = boolean
        ? [...this.optionClose]
        : [...this.optionCustomTime];

      let final = [];

      for (let i = 0; i < cloneCustomTime.length; i++) {
        const x = cloneCustomTime[i];

        for (let j = 0; j < arr.length; j++) {
          const y = arr[j];

          if (x.roundTimeEventId == y) {
            final.push(x);
            break;
          }
        }
      }

      final = final.map((x) => {
        return {
          roundTimeEventId: x.roundTimeEventId,
          endTime: x.timeEnd,
          startTime: x.timeStart,
        };
      });

      return final;
    },

    convertProduct(arr, specialPriceManageTypeId) {
      let cloneOptionCustomTicket = [];
      let final = [];

      if (specialPriceManageTypeId == 1 || specialPriceManageTypeId == 2) {
        cloneOptionCustomTicket = [...this.optionTicket];
      } else {
        cloneOptionCustomTicket = [...this.optionCustomTicket];
      }

      for (let i = 0; i < cloneOptionCustomTicket.length; i++) {
        const x = cloneOptionCustomTicket[i];

        for (let j = 0; j < arr.length; j++) {
          const y = arr[j];

          if (x.productId == y) {
            final.push(x);
          }
        }
      }

      final = final.map((x) => {
        return {
          productId: x.productId,
          price: parseFloat(x.newprice),
        };
      });

      return final;
    },

    clearFormRoundTime() {
      this.formRoundTime = {
        name: "",
        dayOfWeekId: [],
        timeFrom: "",
        numberOfRound: 0,
        productId: [],
        isActive: 1,
      };

      this.errorRoundTime = {
        status: false,
        value: null,
      };

      this.$v.formRoundTime.$reset();
    },

    clearFormCustom() {
      this.formCustom = {
        specialPriceManageTypeId: null,
        productPriceManageGroupId: null,
        fromDateTime: "",
        timeFrom: "",
        toDateTime: null,
        dayOfWeekId: [],
        roundTimeId: [],
        productId: [],
        numberOfRound: 0,
        isActive: 1,
      };

      this.errorCustom = {
        status: false,
        value: null,
      };

      this.optionCustomTicket = [];
      this.isEditCustom = false;

      this.$v.formCustom.$reset();
    },

    clearFormSchedule() {
      this.dataScheduleClone = { ...this.dataSchedule };
    },

    clearFormClose() {
      this.formClose = {
        specialPriceManageTypeId: null,
        productPriceManageGroupId: null,
        fromDateTime: "",
        toDateTime: "",
        dayOfWeekId: [],
        roundTimeId: [],
        productId: [],
        isActive: 1,
      };

      this.isEditClose = false;
      this.$v.formClose.$reset();
    },

    filterPageRoundTime(obj) {
      let { page, take } = obj;

      this.filterRoundTime.page = page;
      this.filterRoundTime.take = take;

      this.getRoundTime();
    },

    filterPageCustom(obj) {
      let { page, take } = obj;

      this.filterCustom.page = page;
      this.filterCustom.take = take;

      this.getCustom();
    },

    filterPageClose(obj) {
      let { page, take } = obj;

      this.filterClose.page = page;
      this.filterClose.take = take;

      this.getClose();
    },

    openModalAlertText(text) {
      this.$emit("openModalAlertText", text);
    },

    convertTime(time) {
      let getTime = `${this.$moment().format("YYYY-MM-DDT")}${time}`;

      return getTime;
    },

    convertTimeHHMM(time) {
      return this.$moment(time).format("HH:mm");
    },

    // ! side bar

    openSidebarEditSchedule() {
      this.$refs.sidebarSchedule.show();
    },

    closeSidebarEditSchedule() {
      this.$refs.sidebarSchedule.hide();
    },

    openSidebarRoundTime() {
      this.isSidebar = 1;
      this.$refs.sidebarRoundTime.show();
    },

    closeSidebarRoundTime() {
      this.$refs.sidebarRoundTime.hide();
    },

    openSidebarCustom() {
      this.isSidebar = 2;

      this.$refs.sidebarCustom.show();
    },

    closeSidebarCustom() {
      this.$refs.sidebarCustom.hide();
    },

    openSidebarClose() {
      this.getOptionRoundTimeEvent();

      this.$refs.sidebarClose.show();
    },

    closeSidebarClose() {
      this.$refs.sidebarClose.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.tap3 {
  background: white;
  padding: 15px;
  min-height: 60vh;

  .font-15 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .title {
    font-size: 18px;

    .icon {
      cursor: pointer;
      font-size: 14px;
    }
  }

  .div-divide {
    border-bottom: 1px solid darkgray;
    padding: 10px 0px;
  }

  .header-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .sec1 {
    margin-top: 10px;
  }

  .text-nodata {
    opacity: 0.5;
    text-align: center;
  }

  .box-ticket {
    display: flex;
    align-items: center;

    .handleprice {
      width: 100%;
      max-width: 100px;
      text-align: center;
    }
  }
}

// ! Override
.btn-filter {
  width: 100%;
  max-width: 120px;
}
</style>
